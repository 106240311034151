/* eslint-disable */

import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import Layout from '../components/Layouts/Inner';
import SEO from '../components/SEO';
import ContainerFixed from '../components/Layouts/ContainerFixed';

const StyledBlogPostWrapper = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 2rem;
`;

const StyledBody = styled.main`
  width: 100%;
  max-width: 680px;
`;

const StyledImage = styled.img`
  max-width: 100%;

  margin: 3rem 0;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.1);
`;

export const query = graphql`
  query($slug: String!) {
    post: contentfulPosts(slug: { eq: $slug }) {
      title
      slug
      date
      childContentfulPostsContentRichTextNode {
        json
      }
    }
  }
`;

const BlogTemplate = ({ data: { post } }) => (
  <Layout>
    <SEO title={post.title} />
    <ContainerFixed>
      <StyledBlogPostWrapper>
        {/* <Img fixed={post.image.fixed} style={{ width: '100%', margin: '3rem' }} /> */}
        <h1
          style={{
            textTransform: 'uppercase',
            textAlign: 'center',
            letterSpacing: 1,
            marginTop: '2rem',
          }}
        >
          {post.title}
        </h1>

        <StyledBody>
          {documentToReactComponents(post.childContentfulPostsContentRichTextNode.json, {
            renderNode: {
              [BLOCKS.EMBEDDED_ASSET]: (node) => {
                if (!node.data.target.fields) {
                  return null;
                }
                return (
                  <StyledImage
                    src={`${node.data.target.fields.file['en-US'].url}`}
                    alt={node.data.target.fields.title['en-US']}
                  />
                );
              },

              [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
            },
          })}
        </StyledBody>
      </StyledBlogPostWrapper>
    </ContainerFixed>
  </Layout>
);

BlogTemplate.propTypes = {
  data: PropTypes.shape({
    post: PropTypes.object,
  }),
};

BlogTemplate.defaultProps = {
  data: {},
};

export default BlogTemplate;
